<template>
    <footer class="mt-10 md:mt-14 lg:mt-36">
        <div v-if="!/^quality-guarantee___/.test($route.name)" class="py-8 md:py-16">
            <div class="container">
                <!--div class="grid grid-cols-12">
<div class="col-span-12 sm:col-span-3 md:col-span-2 md:col-start-2 lg:col-start-3">
<div class="w-24 h-24 lg:w-32 lg:h-32 rounded-full bg-green-200 mx-auto sm:mr-0"></div>
</div>
<div class="col-span-12 sm:col-span-9 md:col-span-8 lg:col-span-6 text-center sm:text-left">
<h2 class="title-2 mb-2">Naturadent garancia</h2>
<p class="mb-8">
    Hiszünk az időálló megoldásokban! Innovatív szolgáltatásainkra és a felhasznált anyagokra is
    garanciát vállalunk.
</p>
<NuxtLink :to="localePath('/')" class="p-component p-button p-button-sm xs-w-full">
    <span class="p-button-label"> Bővebben a garanciáról </span>
 </NuxtLink>
</div -->
                <div
                    class="
                        box-shadow
                        flex
                        px-3
                        pt-5
                        pb-10
                        md:p-8
                        w-full
                        lg:w-8/12
                        mx-auto
                        flex-col
                        lg:flex-row
                        text-4x
                        rounded-lg
                    "
                >
                    <div class="lg:pr-8">
                        <div
                            class="
                                w-27
                                h-27
                                lg:w-32 lg:h-32
                                rounded-full
                                mx-auto
                                mb-6
                                lg:mb-0
                                bg-contain bg-no-repeat bg-center
                            "
                            :style="`background-image: url(${require('~/assets/images/icons/icon-quality_guarantee.svg')})`"
                        ></div>
                    </div>
                    <div class="flex flex-col lg:items-start items-center">
                        <h2 class="title-2 mb-2 text-center lg:text-left">
                            <T t="quality_assurance_box.title" />
                        </h2>
                        <p class="mb-8 text-center lg:text-left">
                            <T t="quality_assurance_box.description" />
                        </p>

                        <ButtonLink
                            :to="localePath({ name: 'quality-guarantee' })"
                            :label="$t('quality_assurance_box.button_label')"
                            class="p-button-md/lg xs-w-full"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="container mb-8 md:mb-12">
            <PartnersCarousel />
        </div>

        <div v-if="$device.isDesktop" class="bg-light-200 py-16">
            <div class="container lists">
                <div class="grid grid-cols-12">
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-5 lg:mb-0 list">
                        <h3 class="font-HKGrotesk-Bold uppercase text-blue mb-5"><T t="generic.contact_info" /></h3>
                        <a v-if="contactData.phone" :href="`tel:${contactData.phone}`" class="nav-item">
                            {{ contactData.phone }}
                        </a>
                        <a v-if="contactData.email" :href="`mailto:${contactData.email}`" class="nav-item">
                            {{ contactData.email }}
                        </a>
                        <a
                            v-if="contactData.google_maps_url && contactData.dental_office"
                            :href="contactData.google_maps_url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="nav-item"
                        >
                            <span class="hover:underline" v-html="contactData.dental_office"></span>
                        </a>
                        <div class="flex items-center mb-12 md:mb-0 mt-10 md:block">
                            <a
                                href="https://www.instagram.com/naturadentlaserclinic/ "
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Instagram"
                            >
                                <InstagramIcon color="#113E51" />
                            </a>
                            <a
                                href="https://www.facebook.com/naturadent/"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="mx-10 md:mx-5 lg:mx-10"
                                aria-label="Facebook"
                            >
                                <FacebookIcon color="#113E51" />
                            </a>
                            <a
                                v-if="false"
                                href="https://twitter.com/naturadent1"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Twitter"
                            >
                                <TwitterIcon color="#113E51" />
                            </a>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-5 sm:mb-0 list">
                        <h3 class="font-HKGrotesk-Bold uppercase text-blue mb-5"><T t="generic.open_hours" /></h3>
                        <div
                            class="nav-item nav-item--no-hover open-hours-details"
                            v-html="contactData.opening_hours"
                        ></div>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-5 lg:mb-0 list">
                        <h3 class="font-HKGrotesk-Bold uppercase text-blue mb-5"><T t="generic.popular_pages" /></h3>
                        <NuxtLink :to="localePath('dental-problems')" class="nav-item">
                            <T t="core.dental_problems" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('services')" class="nav-item"><T t="core.services" /></NuxtLink>
                        <NuxtLink :to="localePath('blog')" class="nav-item"><T t="core.blog" /></NuxtLink>
                        <NuxtLink :to="localePath('works')" class="nav-item"><T t="core.our_works" /></NuxtLink>
                    </div>
                    <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-5 sm:mb-0 list">
                        <h3 class="font-HKGrotesk-Bold uppercase text-blue mb-5">
                            <T t="generic.informations" />
                        </h3>
                        <NuxtLink :to="localePath('payment-methods')" class="nav-item">
                            <T t="core.payment_methods" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('pricing')" class="nav-item">
                            <T t="core.prices" />
                        </NuxtLink>
                        <a
                            :href="$store.state.globals.medicallConsultationLink"
                            target="_blank"
                            class="nav-item cursor-pointer"
                        >
                            <T t="generic.appointment_booking" />
                        </a>
                        <NuxtLink :to="localePath('contact')" class="nav-item">
                            <T t="core.contact" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('faq')" class="nav-item">
                            <T t="core.faq" />
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="bg-light-200 py-5 md:py-8">
            <div class="sm:container">
                <Accordion
                    multiple
                    :active-index="[]"
                    class="p-accordion-secondary"
                    role="menu"
                    aria-label="Footer navigation"
                >
                    <AccordionTab :header="$t('generic.contact_info')">
                        <a v-if="contactData.phone" :href="`tel:${contactData.phone}`" class="nav-item mb-2">
                            {{ contactData.phone }}
                        </a>
                        <a v-if="contactData.email" :href="`mailto:${contactData.email}`" class="nav-item mb-2">
                            {{ contactData.email }}
                        </a>
                        <a
                            v-if="contactData.google_maps_url && contactData.dental_office"
                            :href="contactData.google_maps_url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="nav-item"
                        >
                            <span v-html="contactData.dental_office"></span>
                        </a>
                    </AccordionTab>
                    <AccordionTab :header="$t('generic.open_hours')">
                        <div class="nav-item" v-html="contactData.opening_hours"></div>
                    </AccordionTab>
                    <AccordionTab :header="$t('generic.popular_pages')">
                        <NuxtLink :to="localePath('dental-problems')" class="nav-item mb-2">
                            Fogászati panaszok
                        </NuxtLink>
                        <NuxtLink :to="localePath('services')" class="nav-item mb-2">
                            <T t="core.services" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('blog')" class="nav-item mb-2">
                            <T t="core.blog" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('works')" class="nav-item">
                            <T t="core.our_works" />
                        </NuxtLink>
                    </AccordionTab>
                    <AccordionTab :header="$t('generic.informations')">
                        <NuxtLink :to="localePath('payment-methods')" class="nav-item mb-2">
                            <T t="core.payment_methods" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('pricing')" class="nav-item mb-2">
                            <T t="core.prices" />
                        </NuxtLink>
                        <a :href="$store.state.globals.medicallConsultationLink" target="_blank" class="nav-item mb-2">
                            <T t="generic.appointment_booking" />
                        </a>
                        <NuxtLink :to="localePath('contact')" class="nav-item mb-2">
                            <T t="core.contact" />
                        </NuxtLink>
                        <NuxtLink :to="localePath('faq')" class="nav-item">
                            <T t="core.faq" />
                        </NuxtLink>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
        <div v-if="$device.isMobile" class="flex items-center md:hidden justify-center bg-light-200 pb-8">
            <a href="https://www.instagram.com/naturadentlaserclinic/" target="_blank" aria-label="Instagram">
                <InstagramIcon color="#113E51" />
            </a>
            <a
                href="https://www.facebook.com/naturadent/"
                target="_blank"
                aria-label="Facebook"
                class="mx-10 md:mx-5 lg:mx-10"
            >
                <FacebookIcon color="#113E51" />
            </a>
            <a href="https://twitter.com/naturadent1" target="_blank" aria-label="Twitter">
                <TwitterIcon v-if="false" color="#113E51" />
            </a>
        </div>
        <div class="bg-light-300 py-4">
            <div class="container">
                <div class="flex flex-col md:flex-row md:items-center justify-center">
                    <div class="font-HKGrotesk-SemiBold mb-4 md:mb-0 md:mr-7 text-center text-dark-500">
                        <T t="generic.accepted_payment_methods" />
                    </div>
                    <div class="grid grid-cols-5 gap-x-5">
                        <img v-lazy-load :data-src="require('~/assets/images/logos/creditcards/visa.png')" alt="visa" />
                        <img
                            v-lazy-load
                            :data-src="require('~/assets/images/logos/creditcards/visa_electran.png')"
                            alt="visa electran"
                        />
                        <img
                            v-lazy-load
                            :data-src="require('~/assets/images/logos/creditcards/mastercard.png')"
                            alt="mastercard"
                        />
                        <img
                            v-lazy-load
                            :data-src="require('~/assets/images/logos/creditcards/maestro.png')"
                            alt="maestro"
                        />
                        <img
                            v-lazy-load
                            :data-src="require('~/assets/images/logos/creditcards/american_express.png')"
                            alt="american express"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="bg-dark-700 pt-6 pb-12 md:pb-6"
            :class="{ 'pb-24 pt-6': $store.state.globals.doesAppointmentBookingCardExist && $device.isMobile }"
        >
            <div class="container flex flex-col md:flex-row items-center md:justify-between">
                <div class="flex order-2 md:order-1 flex-col md:flex-row">
                    <object
                        class="mx-auto mb-4 md:mb-0 md:mr-6 w-[46px] h-auto"
                        type="image/svg+xml"
                        title="Naturadent"
                        :data="require('~/assets/images/logos/NaturaDent-logo.svg')"
                    ></object>
                    <span class="text-white text-xs text-center md:text-left">
                        © Naturadent - {{ $t('generic.aesthetical_and_laser_dentistry') }} - 2008 -
                        {{ $dayjs().format('YYYY') }} {{ $t('generic.all_rights_reserved') }}.
                    </span>
                </div>
                <div
                    class="
                        flex
                        order-1
                        md:order-2
                        gap-y-3
                        md:gap-y-0
                        items-center
                        flex-col
                        mb-9
                        md:mb-0 md:flex-row md:gap-x-8
                    "
                >
                    <NuxtLink :to="localePath('terms-and-conditions')" class="text-white text-sm">
                        <T t="core.tos" />
                    </NuxtLink>
                    <NuxtLink :to="localePath('privacy-policy')" class="text-white text-sm">
                        <T t="core.privacy_policy" />
                    </NuxtLink>
                    <NuxtLink :to="localePath('legal-notice')" class="text-white text-sm">
                        <T t="core.legal_notice" />
                    </NuxtLink>
                </div>
            </div>
        </div>
        <div class="bg-white py-16 sm:py-20 md:py-28 hidden">
            <div class="container flex flex-col items-center">
                <div class="flex flex-col md:flex-row items-center">
                    <NuxtLink :to="localePath('terms-and-conditions')" class="md:min-w-32 mb-4 text-center md:mb-0">
                        <T t="core.tos" />
                    </NuxtLink>
                    <NuxtLink :to="localePath('privacy-policy')" class="md:min-w-32 mb-4 text-center md:mb-0 md:mx-20">
                        <T t="core.privacy_policy" />
                    </NuxtLink>
                    <NuxtLink :to="localePath('index')" class="md:min-w-32 text-center">
                        <T t="core.legal_notice" />
                    </NuxtLink>
                </div>
                <div
                    class="h-5 w-12 bg-contain bg-no-repeat bg-center mt-12 mb-12 md:mt-28 md:mb-8"
                    v-lazy-background :lazy-background="require('~/assets/images/logos/NaturaDent-logo.svg')"
                ></div>
                <p class="text-xs text-center">
                    &copy; Naturadent - {{ $t('generic.aesthetical_and_laser_dentistry') }} - 2010 -
                    {{ $dayjs().format('YYYY') }}
                    <br class="md:hidden" />
                    {{ $t('generic.all_rights_reserved') }}.
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import PartnersCarousel from '~/components/Carousels/PartnersCarousel/PartnersCarousel';
import FacebookIcon from '@/components/Icons/Socials/FacebookIcon';
import InstagramIcon from '@/components/Icons/Socials/InstagramIcon';
import TwitterIcon from '@/components/Icons/Socials/TwitterIcon';

export default {
    name: 'Footer',
    components: {
        PartnersCarousel,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
    },
    computed: {
        contactData() {
            return this.$store.state.data.contactData[this.$i18n.locale];
        },
    },
};
</script>

<style scoped>
.box-shadow {
    box-shadow: 0 12px 25px rgba(17, 62, 81, 0.1);
}

.nav-item {
    @apply block text-dark-600 hover:underline;
    &--no-hover {
        @apply hover:no-underline;
    }
}

.list .nav-item {
    @apply md:mb-4 last:mb-0;
}

@media (min-width: 768px) {
    .open-hours-details {
        line-height: 39px;
        margin-top: -7px;
    }
}
</style>
