export const state = () => ({
    scrollY: 0,
    windowWidth: 0,
    prevWindowWidth: 0,
    lastScrollDirection: 'down',
    doesAppointmentBookingCardExist: false,
    isHeaderVisible: false,
    medicallConsultationLink:
        'https://medicall.cc/idopontfoglalas/naturadent/doctors?specializationId=10045368,10045385,10045402,10045448,10045556,10045586', // Alapszolgáltatások - konzultáció
});

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setHeaderVisible(state, payload) {
        state.isHeaderVisible = payload;
    },
    setPrevWindowWidth(state, payload) {
        state.prevWindowWidth = payload;
    },
    setLastScrollDirection(state, payload) {
        state.lastScrollDirection = payload;
    },
    setAppointmentBookingCardExistence(state, payload) {
        state.doesAppointmentBookingCardExist = payload;
    },
};
