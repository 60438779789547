import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f1b0c0a2 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _de317c44 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _75f9bc86 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _09ce1c9d = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _f355f62c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _00fb50e8 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _2b560283 = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _b8905372 = () => interopDefault(import('../pages/dental-problems.vue' /* webpackChunkName: "pages/dental-problems" */))
const _0acbd15c = () => interopDefault(import('../pages/quality-guarantee.vue' /* webpackChunkName: "pages/quality-guarantee" */))
const _03af452e = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _355d5fc3 = () => interopDefault(import('../pages/appointment-booking.vue' /* webpackChunkName: "pages/appointment-booking" */))
const _36e405ee = () => interopDefault(import('../pages/elderly-dental-care.vue' /* webpackChunkName: "pages/elderly-dental-care" */))
const _02a71a58 = () => interopDefault(import('../pages/implant-oral-surgery.vue' /* webpackChunkName: "pages/implant-oral-surgery" */))
const _12e7453a = () => interopDefault(import('../pages/invisalign-orthodontic-treatment.vue' /* webpackChunkName: "pages/invisalign-orthodontic-treatment" */))
const _05acf930 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _2b9692f8 = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _36780938 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _1feb1c80 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _b629e12e = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _0efbc0cc = () => interopDefault(import('../pages/works.vue' /* webpackChunkName: "pages/works" */))
const _20528779 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _840681a4 = () => interopDefault(import('../pages/premium-dental-care.vue' /* webpackChunkName: "pages/premium-dental-care" */))
const _8960c5e4 = () => interopDefault(import('../pages/account/referral-program.vue' /* webpackChunkName: "pages/account/referral-program" */))
const _a50ce946 = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _5c76566e = () => interopDefault(import('../pages/account/appointments.vue' /* webpackChunkName: "pages/account/appointments" */))
const _02a3dd6c = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _34afb518 = () => interopDefault(import('../pages/about/introduction.vue' /* webpackChunkName: "pages/about/introduction" */))
const _2882e0db = () => interopDefault(import('../pages/about/career/index.vue' /* webpackChunkName: "pages/about/career/index" */))
const _9535d09c = () => interopDefault(import('../pages/about/trainings/index.vue' /* webpackChunkName: "pages/about/trainings/index" */))
const _482413e6 = () => interopDefault(import('../pages/about/dentists/index.vue' /* webpackChunkName: "pages/about/dentists/index" */))
const _0c803fe4 = () => interopDefault(import('../pages/about/testimonials.vue' /* webpackChunkName: "pages/about/testimonials" */))
const _dee33116 = () => interopDefault(import('../pages/about/career/preview/_job_preview.vue' /* webpackChunkName: "pages/about/career/preview/_job_preview" */))
const _406cc0ca = () => interopDefault(import('../pages/about/trainings/preview/_training_preview.vue' /* webpackChunkName: "pages/about/trainings/preview/_training_preview" */))
const _0819fe05 = () => interopDefault(import('../pages/about/career/_job.vue' /* webpackChunkName: "pages/about/career/_job" */))
const _09e535f9 = () => interopDefault(import('../pages/about/dentists/_dentist.vue' /* webpackChunkName: "pages/about/dentists/_dentist" */))
const _71ab6af9 = () => interopDefault(import('../pages/about/notifications/_notification.vue' /* webpackChunkName: "pages/about/notifications/_notification" */))
const _f3e38cce = () => interopDefault(import('../pages/about/trainings/_training.vue' /* webpackChunkName: "pages/about/trainings/_training" */))
const _63df30e8 = () => interopDefault(import('../pages/blog/preview/_article_preview.vue' /* webpackChunkName: "pages/blog/preview/_article_preview" */))
const _640b6cd8 = () => interopDefault(import('../pages/about/_content.vue' /* webpackChunkName: "pages/about/_content" */))
const _fb4862c8 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _40ec54e2 = () => interopDefault(import('../pages/services/_service/index.vue' /* webpackChunkName: "pages/services/_service/index" */))
const _497aa32c = () => interopDefault(import('../pages/services/_service/_price.vue' /* webpackChunkName: "pages/services/_service/_price" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adatvedelmi-nyilatkozat",
    component: _f1b0c0a2,
    name: "privacy-policy___hu"
  }, {
    path: "/araink",
    component: _de317c44,
    name: "pricing___hu"
  }, {
    path: "/blog",
    component: _75f9bc86,
    name: "blog___hu"
  }, {
    path: "/elfelejtett-jelszo",
    component: _09ce1c9d,
    name: "forgot-password___hu"
  }, {
    path: "/en",
    component: _f355f62c,
    name: "index___en"
  }, {
    path: "/felhasznalasi-feltetelek",
    component: _00fb50e8,
    name: "terms-and-conditions___hu"
  }, {
    path: "/fizetesi-lehetosegek",
    component: _2b560283,
    name: "payment-methods___hu"
  }, {
    path: "/fogaszati-panaszok",
    component: _b8905372,
    name: "dental-problems___hu"
  }, {
    path: "/garancia",
    component: _0acbd15c,
    name: "quality-guarantee___hu"
  }, {
    path: "/gyakran-ismetelt-kerdesek",
    component: _03af452e,
    name: "faq___hu"
  }, {
    path: "/idopontfoglalas",
    component: _355d5fc3,
    name: "appointment-booking___hu"
  }, {
    path: "/idoskori-fogaszat",
    component: _36e405ee,
    name: "elderly-dental-care___hu"
  }, {
    path: "/implantatum-szajsebeszet",
    component: _02a71a58,
    name: "implant-oral-surgery___hu"
  }, {
    path: "/invisalign-fogszabalyozas",
    component: _12e7453a,
    name: "invisalign-orthodontic-treatment___hu"
  }, {
    path: "/jelszovaltoztatas",
    component: _05acf930,
    name: "change-password___hu"
  }, {
    path: "/jogi-nyilatkozat",
    component: _2b9692f8,
    name: "legal-notice___hu"
  }, {
    path: "/kapcsolat",
    component: _36780938,
    name: "contact___hu"
  }, {
    path: "/kereses",
    component: _1feb1c80,
    name: "search___hu"
  }, {
    path: "/leiratkozas",
    component: _b629e12e,
    name: "unsubscribe___hu"
  }, {
    path: "/munkaink",
    component: _0efbc0cc,
    name: "works___hu"
  }, {
    path: "/szolgaltatasok",
    component: _20528779,
    name: "services___hu"
  }, {
    path: "/en/appointment-booking",
    component: _355d5fc3,
    name: "appointment-booking___en"
  }, {
    path: "/en/blog",
    component: _75f9bc86,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _05acf930,
    name: "change-password___en"
  }, {
    path: "/en/contact",
    component: _36780938,
    name: "contact___en"
  }, {
    path: "/en/dental-problems",
    component: _b8905372,
    name: "dental-problems___en"
  }, {
    path: "/en/faq",
    component: _03af452e,
    name: "faq___en"
  }, {
    path: "/en/forgot-password",
    component: _09ce1c9d,
    name: "forgot-password___en"
  }, {
    path: "/en/invisalign-orthodontic-treatment",
    component: _12e7453a,
    name: "invisalign-orthodontic-treatment___en"
  }, {
    path: "/en/legal-notice",
    component: _2b9692f8,
    name: "legal-notice___en"
  }, {
    path: "/en/payment-methods",
    component: _2b560283,
    name: "payment-methods___en"
  }, {
    path: "/en/premium-dental-care",
    component: _840681a4,
    name: "premium-dental-care___en"
  }, {
    path: "/en/pricing",
    component: _de317c44,
    name: "pricing___en"
  }, {
    path: "/en/privacy-policy",
    component: _f1b0c0a2,
    name: "privacy-policy___en"
  }, {
    path: "/en/quality-guarantee",
    component: _0acbd15c,
    name: "quality-guarantee___en"
  }, {
    path: "/en/search",
    component: _1feb1c80,
    name: "search___en"
  }, {
    path: "/en/services",
    component: _20528779,
    name: "services___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _00fb50e8,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/unsubscribe",
    component: _b629e12e,
    name: "unsubscribe___en"
  }, {
    path: "/en/works",
    component: _0efbc0cc,
    name: "works___en"
  }, {
    path: "/profilom/ajalnasok",
    component: _8960c5e4,
    name: "account-referral-program___hu"
  }, {
    path: "/profilom/beallitasok",
    component: _a50ce946,
    name: "account-settings___hu"
  }, {
    path: "/profilom/idopontjaim",
    component: _5c76566e,
    name: "account-appointments___hu"
  }, {
    path: "/profilom/vezerlopult",
    component: _02a3dd6c,
    name: "account-dashboard___hu"
  }, {
    path: "/rolunk/bemutatkozas",
    component: _34afb518,
    name: "about-introduction___hu"
  }, {
    path: "/rolunk/karrier",
    component: _2882e0db,
    name: "about-career___hu"
  }, {
    path: "/rolunk/kepzeseink",
    component: _9535d09c,
    name: "about-trainings___hu"
  }, {
    path: "/rolunk/orvosaink",
    component: _482413e6,
    name: "about-dentists___hu"
  }, {
    path: "/rolunk/velemenyek",
    component: _0c803fe4,
    name: "about-testimonials___hu"
  }, {
    path: "/en/about/career",
    component: _2882e0db,
    name: "about-career___en"
  }, {
    path: "/en/about/dentists",
    component: _482413e6,
    name: "about-dentists___en"
  }, {
    path: "/en/about/introduction",
    component: _34afb518,
    name: "about-introduction___en"
  }, {
    path: "/en/about/testimonials",
    component: _0c803fe4,
    name: "about-testimonials___en"
  }, {
    path: "/en/about/trainings",
    component: _9535d09c,
    name: "about-trainings___en"
  }, {
    path: "/en/account/appointments",
    component: _5c76566e,
    name: "account-appointments___en"
  }, {
    path: "/en/account/dashboard",
    component: _02a3dd6c,
    name: "account-dashboard___en"
  }, {
    path: "/en/account/referral-program",
    component: _8960c5e4,
    name: "account-referral-program___en"
  }, {
    path: "/en/account/settings",
    component: _a50ce946,
    name: "account-settings___en"
  }, {
    path: "/en/about/career/preview/:job_preview",
    component: _dee33116,
    name: "about-career-preview-job_preview___en"
  }, {
    path: "/en/about/trainings/preview/:training_preview",
    component: _406cc0ca,
    name: "about-trainings-preview-training_preview___en"
  }, {
    path: "/en/about/career/:job",
    component: _0819fe05,
    name: "about-career-job___en"
  }, {
    path: "/en/about/dentists/:dentist",
    component: _09e535f9,
    name: "about-dentists-dentist___en"
  }, {
    path: "/en/about/notifications/:notification",
    component: _71ab6af9,
    name: "about-notifications-notification___en"
  }, {
    path: "/en/about/trainings/:training",
    component: _f3e38cce,
    name: "about-trainings-training___en"
  }, {
    path: "/en/blog/preview/:article_preview",
    component: _63df30e8,
    name: "blog-preview-article_preview___en"
  }, {
    path: "/rolunk/karrier/preview/:job_preview",
    component: _dee33116,
    name: "about-career-preview-job_preview___hu"
  }, {
    path: "/rolunk/kepzeseink/preview/:training_preview",
    component: _406cc0ca,
    name: "about-trainings-preview-training_preview___hu"
  }, {
    path: "/blog/preview/:article_preview",
    component: _63df30e8,
    name: "blog-preview-article_preview___hu"
  }, {
    path: "/en/about/:content",
    component: _640b6cd8,
    name: "about-content___en"
  }, {
    path: "/en/blog/:article",
    component: _fb4862c8,
    name: "blog-article___en"
  }, {
    path: "/en/services/:service",
    component: _40ec54e2,
    name: "services-service___en"
  }, {
    path: "/rolunk/ertesitesek/:notification",
    component: _71ab6af9,
    name: "about-notifications-notification___hu"
  }, {
    path: "/rolunk/karrier/:job",
    component: _0819fe05,
    name: "about-career-job___hu"
  }, {
    path: "/rolunk/kepzeseink/:training",
    component: _f3e38cce,
    name: "about-trainings-training___hu"
  }, {
    path: "/rolunk/orvosaink/:dentist",
    component: _09e535f9,
    name: "about-dentists-dentist___hu"
  }, {
    path: "/en/services/:service/:price",
    component: _497aa32c,
    name: "services-service-price___en"
  }, {
    path: "/blog/:article",
    component: _fb4862c8,
    name: "blog-article___hu"
  }, {
    path: "/rolunk/:content",
    component: _640b6cd8,
    name: "about-content___hu"
  }, {
    path: "/szolgaltatasok/:service",
    component: _40ec54e2,
    name: "services-service___hu"
  }, {
    path: "/szolgaltatasok/:service/:price",
    component: _497aa32c,
    name: "services-service-price___hu"
  }, {
    path: "/",
    component: _f355f62c,
    name: "index___hu"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
